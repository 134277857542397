<template>
    <div class="about">
        <h1 class="about-title">
            About Us
        </h1>
        <div class="about-descript">
            Litely is a fasting community app created by fasting enthusiasts. It aims to help people fast and lose
            weight more easily by getting them to fast together. Litely provides diverse features which specifically
            cater to different groups of people. We are a global team and our team members are located in Canada, the
            UK, the USA, and Asia. We have team members, marketing collaborators, and agencies based in different
            locations to support our global operation. Our team is funded by a fasting enthusiast based in California.
            Our goal is to make Litely the best fasting community in the world.
        </div>
        <h1 class="about-our">
            Our Experts
        </h1>
        <div class="about-content">
            <div class="about-content-model" v-for="(item, ind) in dataList" :key="ind">
                <div class="model-left">
                    <img :src="require(`@/assets/img/${item.key}.jpeg`)">
                </div>
                <div class="model-right">
                    <div class="user-name">
                        {{ item.name }}
                    </div>
                    <div class="user-descript">
                        {{ item.descript }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        this.$store.commit('setIsApp', false)
    },
    data() {
        return {
            dataList: [
                {
                    key: 'Danielle-McAvoy',
                    name: 'Danielle McAvoy',
                    descript: "Danielle is a nutrition expert who specializes in food and wellness. She is a registered dietitian with 11 years experience working as a public health educator."
                },
                {
                    key: 'Hannah-Skaggs',
                    name: 'Hannah Skaggs',
                    descript: "Hannah Skaggs is a US based registered dietitian nutritionist from the state of Michigan. She graduated, Summa Cum Laude from Grand Valley State University with a Master's degree in Clinical Dietetics. She also holds a Bachelor's degree in Nutritional Science from Michigan State University. Hannah's focus areas of practice are in the weight management and clinical nutrition settings."
                },
                {
                    key: 'Daniela-Dimitrova',
                    name: 'Daniela Dimitrova',
                    descript: "Daniela is a Registered Associate Nutritionist, based in London. She has graduated with a BSc Degree in Human Nutrition and has experience of working within the food industry, the NHS, private Nutrition Clinics and as a freelancer. She is committed to helping her clients achieve a balanced and happy relationship with food, by supporting them with evidence-based nutrition advice and focusing on their individual needs and goals."
                },
                {
                    key: 'Stephanie-Small',
                    name: 'Stephanie Small',
                    descript: "Stephanie is a Ph.D. candidate and Registered Dietitian with expertise in intermittent fasting, body composition, carbohydrate and protein metabolism. Stephanie is part of a research team focused on using intermittent fasting in breast cancer survivors and cardiovascular disease in Canada."
                },
                {
                    key: 'Jesse-Hyson',
                    name: 'Jesse Hyson',
                    descript: "Jesse Hyson is an Accredited Exercise Physiologist, business owner and writer. Jesse has been working in the health and fitness industry since 2011, helping personal training clients, athletes and clinical exercise patients achieve their goals."
                },
                {
                    key: 'Brett',
                    name: 'Brett Melanson ',
                    descript: "Brett Melanson obtained his PhD in Psychology & Neuroscience from the University of Guelph in Ontario, Canada. He specializes in nutritional neuroscience, stress-based mood disorders, and mechanisms of antidepressant action."
                },
                // {
                //     key: 'Sophia-Cannizzaro',
                //     name: 'Sophia Cannizzaro',
                //     descript: "Sophia Cannizzaro is an author and editor who specializes in home and self-improvement writing. She also has five years of experience with academic research in the areas of psychology and English."
                // },
                {
                    key: 'Jesse-Feder',
                    name: 'Jesse Feder',
                    descript: "Jesse has a master’s degree in nutrition and dietetics as well as bachelors in exercise physiology. He is an ACSM Personal trainer, registered/licensed dietitian and certified strength and conditioning coach. His is to combine his certifications and degrees to help people with their fitness and nutrition to change their lives."
                },
                {
                    key: 'Christine-VanDoren',
                    name: 'Christine VanDoren',
                    descript: "Christine is a certified personal trainer and nutritionist with an undergraduate degree from Missouri State University. Her passion is helping others learn how strong and healthy they can become by transforming their daily habits. Christine spends most of her time in the gym and learning how she can influence others through positivity!"
                },
                {
                    key: 'Hayley-Zammit',
                    name: 'Hayley Zammit',
                    descript: "Hayley Zammit is a spiritual teacher, author and meditation creator. She supports people in remembering their divinity so they can live a life of more joy, flow and grace."
                },
                // {
                //     key: 'Veronika-Jel',
                //     name: 'Veronika Jel',
                //     descript: "Veronika is a writer who specializes in topics such as mind, mental health, and self-help. She has 2 years of professional experience in the wellness niche and has devoted herself to helping others become their best and healthiest selves."
                // },
                {
                    key: 'David-Tate',
                    name: 'David Tate',
                    descript: "David Tate is a life coach specializing in mental and physical health, nutrition, and fitness. He has 5+ years of experience in the fitness industry, having played semi-professional sports and trained at the highest level."
                },
                {
                    key: 'Andrea-Pinto',
                    name: 'Andrea Pinto',
                    descript: "Dr. Andrea Pinto Lopez is a Medical Doctor who enjoys learning more about nutrition, wellness, and lifestyle. As a General Practitioner, Dr. Pinto Lopez is a passionate advocate for the importance of nourishing our bodies through whole foods, especially plant-based meals. She loves to cook for herself and her loved ones, and she hopes to be able to inspire others to adopt a healthier lifestyle through delicious and healthy food."
                },
                {
                    key: 'Georgie-Tilt',
                    name: 'Georgie Tilt',
                    descript: "Georgie is a registered psychotherapist and adult weight management practitioner who has been working holistically with clients for 7 years supporting them with their mental health, weight concerns, and eating disorders."
                },
                {
                    key: 'Kevin',
                    name: 'Kevin Rail',
                    descript: "Kevin is a world renown writer, and health/fitness professional who has been featured in the documentary films The Motivation Factor and Fasting, He has 25-plus years of experience in the wellness industry and is a Fitness Nutrition Specialist."
                },
                // {
                //     key: 'Steve',
                //     name: 'Steve',
                //     descript: "Steve Theunissen is a freelance writer living in Tauranga, New Zealand. He is a former gym owner, personal trainer and school teacher and is the author of six hardcopy books and more than a hundred ebooks on the topics of bodybuilding, fitness and fat loss. Steve also writes history books with a focus on the history of warfare. He is married and has two daughters."
                // },
                {
                    key: 'Omobolade',
                    name: 'Omobolade Ajibade',
                    descript: "Omobolade Ajibade is a proactive content writer with an educational background in Applied Science at Howard University. She also graduated from Howard University College of Medicine with a Bachelor’s degree in Medicine (MBBS). Omobolade majors in mental health practice management, addiction, and overall health and wellness."
                },
                {
                    key: 'Ashley',
                    name: 'Ashley Derrig',
                    descript: "Ashley Derrig is a limited licensed social worker in Michigan and a healthcare content writer. She has worked in healthcare for over ten years in settings such as hospitals, nursing homes, and community health centers. Ashley has presented at conferences and won her company ‘Business of the Year Award’. She is a strong advocate and educator who focuses on mental health, developmental disabilities, and senior care."
                },
                {
                    key: 'Cynthia',
                    name: 'Cynthia Douglas-Ybarra',
                    descript: "Cynthia Douglas-Ybarra, MSN, PHN, RN has over 22 years of nursing experience in various capacities. She has spent most of her career in the Veteran’s Health Care system caring for our country’s heroes. Her clinical experience includes CV ICU, Medical ICU, L and D, and Medical Surgical Wards. She has also served as Adjunct Faculty for two California Universities and currently works in Clinical Documentation Integrity as a nurse analyst."
                },
                {
                    key: 'Ryan',
                    name: 'Sarah Ryan',
                    descript: "Sarah is a Certified Nutritionist and qualified Biology teacher, with over 10 years of experience helping and educating clients to reach their goals using science-based techniques. She has practiced in Australia, South-East Asia, and Europe to educate people. She is a big believer in small, consistent changes being much more effective long-term than any kind of crash diet. Her life philosophy focuses on balance, and she practices intermittent fasting and healthy eating daily. "
                },
                {
                    key: 'Jacobs',
                    name: 'Lexie Jacobs',
                    descript: "Lexie is a Registered Dietitian who has been working in what is called a skilled rehabilitation center and long-term care center since 2020. Her patients can be admitted for rehab services such as physical, occupational or speech therapy, IV antibiotics, wound healing, and/or dialysis."
                },


            ]
        }
    },
}
</script>

<style scoped lang="less">
@import "@/css/less_custom.less";

.about {
    padding: 0 122px;
    color: #333333;

    .about-title {
        font-size: 40px;
    }

    .about-descript {
        font-size: 20px;
        font-weight: 500;
        font-family: 'AvenirNext';
        margin: 32px 0 72px;
        text-align: left;
    }

    .about-our {
        font-size: 30px;
        text-align: left;
        margin-bottom: 32px;
    }

    .about-content {
        display: flex;
        flex-wrap: wrap;

        .about-content-model {
            width: calc((100% - 64px) / 2);
            margin-right: 64px;
            border: 1px solid #E6E6E6;
            box-sizing: border-box;
            margin-bottom: 48px;
            padding: 0 20px 0 0;
            display: flex;
        }

        .about-content-model:nth-child(2n) {
            margin-right: 0;
        }

        .model-left {
            width: 236px;
            height: 236px;
            display: flex;
            margin-right: 28px;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
            }
        }

        .model-right {
            flex: 1;

            .user-name {
                font-size: 25px;
                font-family: 'AvenirNext';
                text-align: left;
                font-weight: 500;
                margin: 20px 0;
                line-height: 25px;
            }

            .user-descript {
                line-height: 16px;
                text-align: left;
                font-size: 12px;
                font-family: 'AvenirNext';
            }
        }
    }


}

@media screen and (max-width: @MobileMaxWidth) {
    .about {

        padding: 0 27px;

        .about-title {
            font-size: 28px;
            margin: 0;
        }

        .about-descript {
            font-size: 14px;
            margin: 19px 0 48px;
        }

        .about-our {
            display: none;
        }

        .about-content {
            .about-content-model {
                width: 100%;
                margin-right: 0;
                flex-direction: column;
                padding: 0;
                padding-bottom: 18px;
            }

            .model-left {
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                position: relative;

                img {
                    width: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .model-right {
                margin: 8px;

                .user-name {
                    margin: 20px 0;
                    font-size: 21px;
                    font-family: 'AvenirNext';
                }

                .user-descript {
                    font-size: 12px;
                }
            }
        }

    }
}
</style>